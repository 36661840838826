
import React     from 'react';
import PropTypes from 'prop-types';

import { openUrl, removeLinkProtocol } from 'src/core/util/JsTools';


const Url = ({
    label,
    href,
    target,
    openInInAppBrowser,
    onClick,
    beforeAction,
    className,
    children,
    callback,
    noCache,
    ...props
}) => {

    if (typeof onClick === 'function') {
        console.error('Do not override \'onClick\' property, use \'callback\' prop instead', props);
    }
    if (typeof callback !== 'function') {
        callback = global.noop;
    }

    function openUrlCallback(result) {
        if (result === true) {
            callback(href);
        }
    }

    const handleClick = (e) => {
        e.preventDefault()

        let goAhead = true

        if (beforeAction && typeof beforeAction === 'function') {
            goAhead = beforeAction()
        }

        if (href && goAhead) {
            const url = noCache ? href + `?rnd=${new Date().getTime()}` : href;

            openUrl(url, openUrlCallback, target, openInInAppBrowser);
        }
    };

    return <a className={`link color-link-blue ${!href ? 'empty-link' : ''} ${className || ''}`}
              onClick={handleClick}
              {...props}>{removeLinkProtocol(label) || children}</a>
};

Url.propTypes = {
    href     : PropTypes.string,
    target   : PropTypes.string,
    openInInAppBrowser: PropTypes.bool,
    label    : PropTypes.string, // not required as this component can wrap an image for instance
    className: PropTypes.string,
    callback : PropTypes.func,
};

export default Url;
