import { DATA_TYPE_INFOS, DATA_TYPE_PMRS } from './dataConfig';
import { CATEGORIES_CLASSIFICATIONS } from 'src/core/query/Query'; // import {  } from 'src/pages/pagesKeys';

export function isLogoFullWidth(pageKey) {
  switch (pageKey) {
    default:
      return true;
  }
}
export var EVENT_PAGE_SPEAKERS_AS_ACCORDION = false;
export var DEFAULT_IMAGE = null;
export var SINGLE_ITEM_DATATYPES = [DATA_TYPE_INFOS, DATA_TYPE_PMRS]; // CATEGORY_SYSTEM || TOP_CATS

export var CATEGORIES_CLASSIFICATION = CATEGORIES_CLASSIFICATIONS.TOP_CATS;