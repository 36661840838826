module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'INDEX2020_D'
    },
    id: 'com.mobilespot.index.dev',
    // iOS
    appleTeamId: '1487813722',
    //          iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ''
  },
  web: {
    url: 'index2020-dev.mobile-spot.com'
  }
});