// NB: to build a cordova app, relative path is needed here
var _require = require("../../src/pages/pagesKeys"),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    LIST_PAGE_KEY = _require.LIST_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY = _require.LIST_GROUPS_PAGE_KEY;

var dataConfig = require("./dataConfig");

var _require2 = require("../../src/core/webservices/WsProviders"),
    TAIGA = _require2.TAIGA;

var projectConfig = require("../../../data/config.js");

var _require3 = require("./profiles"),
    DEFAULT_PROFILE = _require3.DEFAULT_PROFILE,
    WEBAPP_EXH_PROFILE = _require3.WEBAPP_EXH_PROFILE,
    WEBAPP_EVT_PROFILE = _require3.WEBAPP_EVT_PROFILE,
    WEBAPP_EVT_PROFILE2 = _require3.WEBAPP_EVT_PROFILE2,
    WEBAPP_EVT_PROFILE3 = _require3.WEBAPP_EVT_PROFILE3,
    WEBAPP_EVT_PROFILE4 = _require3.WEBAPP_EVT_PROFILE4,
    WEBAPP_EVT_PROFILE5 = _require3.WEBAPP_EVT_PROFILE5,
    WEBAPP_EVT_PROFILE6 = _require3.WEBAPP_EVT_PROFILE6,
    WEBAPP_EVT_PROFILE7 = _require3.WEBAPP_EVT_PROFILE7;

var _require4 = require("./dataConfig"),
    DATA_TYPE_EVENT_CATEGORIES = _require4.DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS = _require4.DATA_TYPE_EVENTS; // Only export a default object, to be able to
// override properties depending on target environment


module.exports = {
  EVENT_NAME: "INDEX 2020",
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  //ms
  SHOW_TERMS_OF_USE_ON_HOME: true,
  // Terms of use URL
  TOU_LINK: {},
  // App profiles
  SUPPORTED_PROFILES: [DEFAULT_PROFILE, WEBAPP_EXH_PROFILE, WEBAPP_EVT_PROFILE, WEBAPP_EVT_PROFILE2, WEBAPP_EVT_PROFILE3, WEBAPP_EVT_PROFILE4, WEBAPP_EVT_PROFILE5, WEBAPP_EVT_PROFILE6, WEBAPP_EVT_PROFILE7],
  DEFAULT_PROFILE: DEFAULT_PROFILE,
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage(profile) {
    switch (profile) {
      case DEFAULT_PROFILE:
        return {
          pageKey: HOME_PAGE_KEY,
          props: null
        };

      case WEBAPP_EXH_PROFILE:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            hasFilter: true,
            inputs: [{
              dataType: dataConfig.DATA_TYPE_EXHIBITORS
            }]
          }
        };

      case WEBAPP_EVT_PROFILE:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 1 // "Full programme"

            }
          }
        };

      case WEBAPP_EVT_PROFILE2:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 29 // Exhibitor Product Presentations

            }
          }
        };

      case WEBAPP_EVT_PROFILE3:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 30 // Sector Seminar - Infection Prevention

            }
          }
        };

      case WEBAPP_EVT_PROFILE4:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 31 // Sector Seminar - Filtration

            }
          }
        };

      case WEBAPP_EVT_PROFILE5:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 32 // Sector Seminar - Smart Nonwovens

            }
          }
        };

      case WEBAPP_EVT_PROFILE6:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 7 // Sector Seminar - Transportation

            }
          }
        };

      case WEBAPP_EVT_PROFILE7:
        return {
          // pageKey: LIST_PAGE_KEY,
          // props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
          pageKey: LIST_GROUPS_PAGE_KEY,
          //                props: { input: { dataType: DATA_TYPE_EVENTS }}
          props: {
            input: {
              dataType: DATA_TYPE_EVENTS,
              parentType: DATA_TYPE_EVENT_CATEGORIES,
              parentId: 34 // Sector Seminar - Geosynthetics & Environment 

            }
          }
        };

      default:
        console.error("Bad configuration, unexpected profile: " + profile);
    }
  },
  APP_TITLE_SEPARATOR: " - ",
  ROUTE_SEPARATOR: "?",
  // Lang
  SUPPORTED_LANGS: ["en"],
  DEFAULT_LANG: "en",
  GOOGLE_ANALYTICS_SEND_INTERVAL: 4000,
  // Backend / Updates
  UPDATE_ENABLED: true,
  BO_SSH_USER: "www",
  LIST_GROUPS_SHOW_SIDEINDEX_IF_ABOVE: 20,
  // Map
  MOBIGEO_API_KEY: "oR9voo2k",
  // Mobile-Spot link
  //    MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: "",
  //  on LinkedIn
  LINKEDIN_API_KEY: "",
  //  on Facebook
  FACEBOOK_APP_ID: "",
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: "",
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,

  /**
   * FEATURES
   */
  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false // webservice url is defined per environment

  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: {
    FEATURE_ENABLED: false,
    PROVIDER: TAIGA,
    // Note: webservice url is defined per environment
    forgottenPasswordURL: null,
    createAccountURL: null,
    tosURL: null
  },
  POLL: {
    FEATURE_ENABLED: false
  },
  PROFILE: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: false,
    PROVIDER: TAIGA,
    FIELDS: {
      profilePicture: {
        type: "image",
        editable: true
      },
      firstName: {
        type: "text",
        required: true,
        editable: false
      },
      lastName: {
        type: "text",
        required: true,
        editable: false
      },
      organisation: {
        type: "text",
        editable: false
      },
      position: {
        type: "text",
        required: true,
        editable: false
      },
      email: {
        type: "email",
        required: true,
        pattern: /^[\w._+-]+@[\w.-]+\.[A-Za-z]{2,64}$/,
        editable: true
      },
      phone: {
        type: "tel",
        required: true,
        pattern: /^[ \.\+0-9]*$/,
        editable: true
      },
      visible: {
        type: "bool",
        required: true,
        editable: true
      },
      contactable: {
        type: "bool",
        required: true,
        editable: true
      }
    }
  },
  PARTICIPANTS: {
    PROVIDER: TAIGA,
    DATA_EXPIRE_DELAY: 1 * 60 * 1000
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: "",
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  NOTES: {
    FEATURE_ENABLED: true,
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    PUBLIC_KEY: "eN/sCwOHNN+42fjwDoLj35xaVN0OgnXsxzV8+yvu0oy0Ea3Klk19j3EEN3uPZkw3HR8HlEpOSg999rsBwwklDv/BoRNt8RhYZoCyDfmcfhusV/w8go7jtw6JI4q4P8nfB6QgotAfpAYG/ijCOzbbXI/WG1uppA2DP8R6vb7d7WM=",
    UNAUTHORIZED_MODE_ENABLED: true,
    // webservice url is defined per environment
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms

  },
  SOCIAL: {
    FEATURE_ENABLED: true
  },
  SYNOPTIC_AGENDA: {
    AUTOFOCUS_TAB: true,
    DEFAULT_INPUT: {
      agendaProps: {
        input: {
          parentId: 1,
          parentType: DATA_TYPE_EVENT_CATEGORIES,
          dataType: DATA_TYPE_EVENTS
        }
      }
    }
  }
};