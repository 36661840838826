
import { NEWPRODUCT_PAGE_KEY } from 'src/pages/pagesKeys';

import NewProductContent from './NewProductContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

export default {
    key      : NEWPRODUCT_PAGE_KEY,
    path     : '/newproduct',
    elId     : DOM_ID,
    className: 'newproduct-page',
    component: GenericItemPage,
    childComponent: NewProductContent,
    relatedDataToFetch: [ 'exhibitor' ],
};
