
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import SearchPageContent from './SearchPageContent';

import * as actions from 'src/store/actions';

import './SearchPage.scss';


const LOG_PREF = '[SearchPage] ';


class SearchPage extends Component {

    constructor(props) {
        super(props);
        this.pageKey = SEARCH_PAGE_KEY;
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.labels.search.title;
    }

    componentDidMount() {
        this.setDocumentContext();
    }

    componentDidUpdate() {
        this.setDocumentContext();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // relaunch === true when data has been updated (including on language switch)
        if (this.props.relaunch !== nextProps.relaunch && nextProps.relaunch === true) {
            this.performSearch(this.props.searched);
            return false;
        }
        return true;
    }

    performSearch = str => {
        this.props.actions.performSearch(str, SEARCH_PAGE_KEY);
    }

    render() {
        console.log(LOG_PREF+'render');

        // Avoid resetting contextual search field
        if (!this.props.isVisible && !this.props.searched) {
            return null;
        }

        return (
            <>
                <Menu
                    options={this.props.menuOptions}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    profile={this.props.profile}
                    associatedPageKey={this.pageKey}
                    adConfig={this.props.adConfig}
                    twoColumns={this.props.twocolumns}
                    isLocationEnabled={this.props.isLocationEnabled}
                    />

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.getPageTitle()}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}
                    disableDropShadow={true}
                    />

                <SearchPageContent
                    isPending={this.props.isPending}
                    placeholder={this.props.placeholder}
                    favIconDisabled={this.props.favIconDisabled}
                    favorites={this.props.favorites}
                    performSearch={this.performSearch}
                    searched={this.props.searched}
                    results={this.props.results}
                    totalCount={this.props.totalCount}
                    userData={this.props.userData}
                    keyboardHeight={this.props.keyboardHeight}
                    actions={this.props.actions}
                    labels={this.props.labels} />
            </>
        );
    }
}

SearchPage.propTypes = {
    placeholder: PropTypes.string,
    isPending  : PropTypes.bool,
    relaunch   : PropTypes.bool,
    results    : PropTypes.object,
    favorites  : PropTypes.object,
    favIconDisabled: PropTypes.bool,
    userData   : PropTypes.object,
    // Common page props
    menuOptions       : PropTypes.object.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    keyboardHeight    : PropTypes.number,
    isVisible         : PropTypes.bool, // set by togglePageAfterNavigation common reducer function
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[SEARCH_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPage);
