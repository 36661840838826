module.exports = {
  DEFAULT_PROFILE: 'default',
  WEBAPP_EXH_PROFILE: 'webapp-exh',
  WEBAPP_EVT_PROFILE: 'webapp-evt',
  WEBAPP_EVT_PROFILE2: 'webapp-evt2',
  WEBAPP_EVT_PROFILE3: 'webapp-evt3',
  WEBAPP_EVT_PROFILE4: 'webapp-evt4',
  WEBAPP_EVT_PROFILE5: 'webapp-evt5',
  WEBAPP_EVT_PROFILE6: 'webapp-evt6',
  WEBAPP_EVT_PROFILE7: 'webapp-evt7'
};