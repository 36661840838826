/**
 * Declare pages used by the app
 * (Unnecessary pages won't be compiled nor included in js build)
 *
 * A page definition usually looks like this:
 *
 * key      : a unique identifier for the page (allowing to do: `navigate(HOME_PAGE_KEY);` or in redux reducers: `state[HOME_PAGE_KEY]`)
 * path     : used for routing, accessing directly to a page, bookmarking
 * component: React High Order Component for the page
 * elId     : unique id of the created <div id={elId}> element (@see `Router.mount()`) on which ReactDOM mounts the component
 */
// import aircraftPageConfig        from 'src/pages/aircraft/config';
// import choosePageConfig          from 'src/pages/choose-profile/config';
import eventPageConfig from 'src/pages/event/config';
import speakerPageConfig from 'src/pages/speaker/config';
import paperPageConfig from 'src/pages/paper/config';
import exhibitorPageConfig from 'src/pages/exhibitor/config'; // import experiencesItemPageConfig from 'src/pages/experience_item/config';

import favoritesPageConfig from 'src/pages/favorites/config'; // import flightsSchedulePageConfig from 'src/pages/flights_schedule/config';

import homePageConfig from 'src/pages/home/config'; // import hoursPageConfig           from 'src/pages/hours/config';

import inboxPageConfig from 'src/pages/inbox/config';
import listPageConfig from 'src/pages/list/config';
import listGroupsPageConfig from 'src/pages/list-groups/config'; // import loginPageConfig           from 'src/pages/login/config';

import mapPageConfig from 'src/pages/map/config';
import mediasPageConfig from 'src/pages/medias/config';
import newProductPageConfig from 'src/pages/new-product/config';
import participantPageConfig from 'src/pages/participant/config'; // import pmrPageConfig             from 'src/pages/pmr/config';
// import restaurantPageConfig      from 'src/pages/restaurant/config';

import searchPageConfig from 'src/pages/search/config';
import servicePageConfig from 'src/pages/service/config'; // import docunitPageConfig         from 'src/pages/docunit/config';

import synopticAgendaPageConfig from 'src/pages/synoptic-agenda/config';
import notesPageConfig from 'src/pages/notes/config'; // import userDataPageConfig        from 'src/pages/user-data/config';

export default [// aircraftPageConfig,
// choosePageConfig,
eventPageConfig, speakerPageConfig, paperPageConfig, exhibitorPageConfig, // experiencesItemPageConfig,
favoritesPageConfig, // flightsSchedulePageConfig,
homePageConfig, // hoursPageConfig,
inboxPageConfig, listPageConfig, listGroupsPageConfig, // loginPageConfig,
mapPageConfig, mediasPageConfig, newProductPageConfig, participantPageConfig, // pmrPageConfig,
// restaurantPageConfig,
searchPageConfig, servicePageConfig, // docunitPageConfig
synopticAgendaPageConfig, notesPageConfig // userDataPageConfig,
];