var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'index2020-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/palexpo/index2020/index2020-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/palexpo/index2020/index2020-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-26',
  // Pushwoosh
  projectId: '970094533591',
  // FCM sender id
  appId: 'C8314-C4BC5',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  }
});